<template>
	<div :id="`block-${$vnode.key}`" class="c-block-list-spot-box relative">
		<template v-if="image && (image.cropUrl || image.url)">
			<BaseImg
				class=">=768:hidden max-w-full z-10"
				:src="image.cropUrl || image.url"
				:alt="image.altText"
				:width="image.width"
				:height="image.height"
				:ratio="343 / 197"
				loading="lazy"
			/>
			<BaseImg
				class="hidden >=768:block max-w-full z-10"
				:src="image.cropUrl || image.url"
				:alt="image.altText"
				:width="image.width"
				:height="image.height"
				:ratio="864 / 331"
				loading="lazy"
			/>
		</template>
		<div class="bg-theme text-white py-md/v px-md/h >=768:mr-92 <768:mb-92">
			<small v-if="label" class="text-label-sm uppercase">
				{{ label }}
			</small>
			<LongReadTarget
				v-if="title"
				class="relative z-10"
				:container-id="`block-${$vnode.key}`"
				:link-to-toc="{
					name: `Spring til &quot;${title}&quot; i indholdsfortegnelsen`,
					attrs: {
						'data-focus-ignore': true,
						class: 'bg-white text-red',
					},
				}"
				:title="title"
				:disabled="!showTitleInToc"
			>
				<BaseH2 v-html="title"></BaseH2>
			</LongReadTarget>
			<div
				v-if="teaser"
				class="s-rich-text s-rich-text--monotone text-body mt-2xs/v"
				v-html="teaser"
			></div>
			<BaseButton
				v-if="link && link.url"
				class="
					ml-auto
					mt-md/v
					justify-between
					flex-shrink-0 flex-grow-0
					gap-x-4xs/h
				"
				:class="[
					'bg-white hover:bg-theme-10 text-theme border border-current',
					link.type === 'media' ? 'matomo_download' : 'matomo_link',
				]"
				:to="link.url"
				:target="
					link.type &&
					link.type === 'content' &&
					link.target !== '_blank'
						? ''
						: '_blank'
				"
				:download="link.type === 'media' ? link.name : null"
			>
				<span v-text="linkText || link.name"></span>
			</BaseButton>

			<!-- GRAPHIC ITEMS -->
			<div
				class="
					absolute
					>=768:w-92
					w-full
					>=768:h-full
					h-92
					bg-theme
					opacity-20
					>=768:right-0 >=768:top-0
					<768:bottom-0 <768:left-0
				"
			></div>
			<!-- ONLY TEXT AND TITLE -->
			<div
				v-if="!link && !image"
				class="bg-white w-92 h-92 absolute bottom-0 right-0"
			></div>
			<div
				v-if="!link && !image"
				class="
					bg-theme
					opacity-40
					rounded-full
					w-92
					h-92
					absolute
					bottom-0
					right-0
				"
			></div>
			<!-- WITH LINK -->
			<div
				v-if="link && !image"
				class="
					bg-white
					w-92
					<768:w-184
					h-184
					<768:h-92
					absolute
					bottom-0
					right-0
				"
			></div>
			<div
				v-if="link && !image"
				class="
					bg-theme
					opacity-40
					>=768:rounded-bl-full
					rounded-tl-full
					w-92
					<768:rounded-tr-full <768:w-184
					h-184
					<768:h-92
					absolute
					bottom-0
					right-0
				"
			></div>
			<!-- WITH IMAGE -->
			<div
				v-if="image"
				class="bg-theme opacity-40 w-92 h-92 absolute bottom-0 right-0"
			></div>
			<div
				v-if="image"
				class="absolute bottom-0 right-0"
				style="
					width: 0;
					height: 0;
					border-bottom: 92px solid transparent;
					border-left: 92px solid white;
				"
			></div>
		</div>
	</div>
</template>

<script>
import { LongReadTarget } from '~/components/shared/LongRead';
export default {
	name: 'BlockListSpotBox',
	components: { LongReadTarget },
	inheritAttrs: false,
	props: {
		title: String,
		teaser: String,
		label: String,
		showTitleInToc: Boolean,
		image: {
			type: Object,
			default: () => ({}),
		},
		link: {
			type: Object,
			default: () => ({}),
		},
		linkText: String,
	},
};
</script>
