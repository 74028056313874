var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-block-list-spot-box relative",attrs:{"id":("block-" + (_vm.$vnode.key))}},[(_vm.image && (_vm.image.cropUrl || _vm.image.url))?[_c('BaseImg',{staticClass:">=768:hidden max-w-full z-10",attrs:{"src":_vm.image.cropUrl || _vm.image.url,"alt":_vm.image.altText,"width":_vm.image.width,"height":_vm.image.height,"ratio":343 / 197,"loading":"lazy"}}),_vm._v(" "),_c('BaseImg',{staticClass:"hidden >=768:block max-w-full z-10",attrs:{"src":_vm.image.cropUrl || _vm.image.url,"alt":_vm.image.altText,"width":_vm.image.width,"height":_vm.image.height,"ratio":864 / 331,"loading":"lazy"}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"bg-theme text-white py-md/v px-md/h >=768:mr-92 <768:mb-92"},[(_vm.label)?_c('small',{staticClass:"text-label-sm uppercase"},[_vm._v("\n\t\t\t"+_vm._s(_vm.label)+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.title)?_c('LongReadTarget',{staticClass:"relative z-10",attrs:{"container-id":("block-" + (_vm.$vnode.key)),"link-to-toc":{
				name: ("Spring til \"" + _vm.title + "\" i indholdsfortegnelsen"),
				attrs: {
					'data-focus-ignore': true,
					class: 'bg-white text-red',
				},
			},"title":_vm.title,"disabled":!_vm.showTitleInToc}},[_c('BaseH2',{domProps:{"innerHTML":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),(_vm.teaser)?_c('div',{staticClass:"s-rich-text s-rich-text--monotone text-body mt-2xs/v",domProps:{"innerHTML":_vm._s(_vm.teaser)}}):_vm._e(),_vm._v(" "),(_vm.link && _vm.link.url)?_c('BaseButton',{staticClass:"\n\t\t\t\tml-auto\n\t\t\t\tmt-md/v\n\t\t\t\tjustify-between\n\t\t\t\tflex-shrink-0 flex-grow-0\n\t\t\t\tgap-x-4xs/h\n\t\t\t",class:[
				'bg-white hover:bg-theme-10 text-theme border border-current',
				_vm.link.type === 'media' ? 'matomo_download' : 'matomo_link' ],attrs:{"to":_vm.link.url,"target":_vm.link.type &&
				_vm.link.type === 'content' &&
				_vm.link.target !== '_blank'
					? ''
					: '_blank',"download":_vm.link.type === 'media' ? _vm.link.name : null}},[_c('span',{domProps:{"textContent":_vm._s(_vm.linkText || _vm.link.name)}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\tabsolute\n\t\t\t\t>=768:w-92\n\t\t\t\tw-full\n\t\t\t\t>=768:h-full\n\t\t\t\th-92\n\t\t\t\tbg-theme\n\t\t\t\topacity-20\n\t\t\t\t>=768:right-0 >=768:top-0\n\t\t\t\t<768:bottom-0 <768:left-0\n\t\t\t"}),_vm._v(" "),(!_vm.link && !_vm.image)?_c('div',{staticClass:"bg-white w-92 h-92 absolute bottom-0 right-0"}):_vm._e(),_vm._v(" "),(!_vm.link && !_vm.image)?_c('div',{staticClass:"\n\t\t\t\tbg-theme\n\t\t\t\topacity-40\n\t\t\t\trounded-full\n\t\t\t\tw-92\n\t\t\t\th-92\n\t\t\t\tabsolute\n\t\t\t\tbottom-0\n\t\t\t\tright-0\n\t\t\t"}):_vm._e(),_vm._v(" "),(_vm.link && !_vm.image)?_c('div',{staticClass:"\n\t\t\t\tbg-white\n\t\t\t\tw-92\n\t\t\t\t<768:w-184\n\t\t\t\th-184\n\t\t\t\t<768:h-92\n\t\t\t\tabsolute\n\t\t\t\tbottom-0\n\t\t\t\tright-0\n\t\t\t"}):_vm._e(),_vm._v(" "),(_vm.link && !_vm.image)?_c('div',{staticClass:"\n\t\t\t\tbg-theme\n\t\t\t\topacity-40\n\t\t\t\t>=768:rounded-bl-full\n\t\t\t\trounded-tl-full\n\t\t\t\tw-92\n\t\t\t\t<768:rounded-tr-full <768:w-184\n\t\t\t\th-184\n\t\t\t\t<768:h-92\n\t\t\t\tabsolute\n\t\t\t\tbottom-0\n\t\t\t\tright-0\n\t\t\t"}):_vm._e(),_vm._v(" "),(_vm.image)?_c('div',{staticClass:"bg-theme opacity-40 w-92 h-92 absolute bottom-0 right-0"}):_vm._e(),_vm._v(" "),(_vm.image)?_c('div',{staticClass:"absolute bottom-0 right-0",staticStyle:{"width":"0","height":"0","border-bottom":"92px solid transparent","border-left":"92px solid white"}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }